<template>
    <div class="footer">
        <div class="borders"></div>
      <div class="content">
        <div class="wrap">
          <div class="item">Copyright©FancyDigital.All Rights reserved 闽ICP备2023014151号-1 </div>
          <a class="item" @click="goxieyi(2)"> 隐私政策 |&nbsp; </a>
          <a class="item" @click="goxieyi(1)"> 服务协议 </a>
        </div>

      </div>
    </div>
</template>

<script>
export default{
    data() {
        return{

        }
    },
  methods: {
    goxieyi(index) {
      console.log(index)
      this.$router.push({
        path: '/agreement',
        query: {
          id: index
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.footer{
    .borders{
        width:100%;
        height: 1px;
        border-bottom: 1px solid #DEDEDE;
        margin-bottom: 20px;
    }
  .content{

    width: 1200px;
    padding-bottom: 20px;
    margin: 0 auto 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .wrap{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .item{
      color: rgba($color: #1F1F1F, $alpha: 0.6);
      font-size: 14px;
      font-weight: 400;

    }
  }
}
</style>
