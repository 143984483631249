<template>
  <div class="headers">
    <div class="content">
      <div class="logoOrAdress">
        <img class="logo" style="width: 36px;height: 36px" @click="goHome" src="https://static.dasdt.cn/images/logo2.png" alt="">
        <img class="logo" style="margin-left: 8px;margin-right: 30px" @click="goHome" src="https://static.dasdt.cn/images/logo.png" alt="">

        <chooseArea @changeCity="changeCity" v-model="openDialog">
          <div class="adress" @click="openDialog = true">
            <img class="iocn1" src="https://static.dasdt.cn/images/adress.png" alt="">
            <span>{{ this.$store.getters.area.name }}</span>
            <img class="icon2" src="https://static.dasdt.cn/images/down.png" alt="">
          </div>
        </chooseArea>
      </div>
      <div class="right">
        <div class="nav">
          <div class="item" @click="Selected(1)" :class="{current:current===1}">首页</div>
          <div class="item" @click="Selected(5)" :class="{current:current===5}">推荐</div>
          <div class="item" @click="Selected(2)" :class="{current:current===2}">职位库</div>
          <div class="item" @click="Selected(4)" :class="{current:current===4}">学习</div>
          <el-badge :value="messageNum" v-if="messageNum>0">
            <div class="item" @click="Selected(3)" :class="{current:current===3}">消息</div>
          </el-badge>
          <div class="item" v-else @click="Selected(3)" :class="{current:current===3}">消息</div>
          <!--          <div class="messageNum">{{messageNum}}</div>-->
        </div>
        <el-popover
            placement="bottom"
            width="180"
            trigger="hover">
          <div class="popover">
            <div class="item" @click="goPersonCenter">
              <div class="left">
                <img class="icon" src="https://static.dasdt.cn/images/userCenter/user1.png">
                <span>简历中心</span>
              </div>
              <img class="icon2" src="https://static.dasdt.cn/images/right4.png">
            </div>
            <div class="item" @click="goPrivacy">
              <div class="left">
                <img class="icon" src="https://static.dasdt.cn/images/userCenter/user2.png">
                <span>隐私设置</span>
              </div>
              <img class="icon2" src="https://static.dasdt.cn/images/right4.png">
            </div>
            <div class="item" @click="goMessageCenter">
              <div class="left">
                <img class="icon" src="https://static.dasdt.cn/images/swatch.png">
                <span>切换招聘者</span>
              </div>
              <img class="icon2" src="https://static.dasdt.cn/images/right4.png">
            </div>
            <div class="item" @click="goFeedback">
              <div class="left">
                <img class="icon" src="https://static.dasdt.cn/images/userCenter/user4.png">
                <span>建议反馈</span>
              </div>
              <img class="icon2" src="https://static.dasdt.cn/images/right4.png">
            </div>
            <div class="item" @click="quit">
              <div class="left">
                <img class="icon" src="https://static.dasdt.cn/images/userCenter/user5.png">
                <span>退出登录</span>
              </div>
              <img class="icon2" src="https://static.dasdt.cn/images/right4.png">
            </div>
          </div>
          <div class="userInfo" slot="reference" v-if="isLogin">
            <img :src="getFace($store.getters.userInfo)" alt="">
            <span v-if="$store.getters.userInfo?.name">{{ $store.getters.userInfo?.name }}</span>
            <span v-else>姓名</span>
          </div>
        </el-popover>
        <div class="login" @click="open" v-if="!isLogin">
          登录/注册
        </div>
      </div>
    </div>
    <logins v-model="isShow2"/>
  </div>
</template>

<script>
import chooseArea from "@/components/chooseArea/index.vue";
// import {getToken} from "@/utils/auth";
import logins from '@/components/login/index.vue'
import {messageunread} from "@/api/currency";
import store from "@/store";

export default {
  components: {chooseArea, logins},
  data() {
    return {
      openDialog: false,
      isShow2: false,
      checked: true,
      current: 0,
      city: '上海',
      messageNum: 0
    }
  },
  props: {
    Read: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    Read(newVal) {
      if (newVal) {
        this.messageunread()
      }
    }
  },
  computed: {
    store() {
      return store
    },
    isLogin() {
      return this.$store.getters.token !== '0';
      // return this.$store.getters.token !== null;
    }
  },
  created() {
    if (this.$route.path === '/') {
      this.current = 1
    } else if (this.$route.path === '/position' || this.$route.path === '/positionDetails') {
      this.current = 2
    } else if (this.$route.path === '/course') {
      this.current = 4
    }else if(this.$route.path === '/recommendedPositions') {
      this.current = 5
    }else if(this.$route.path === '/MessageCenter') {
      this.current = 3
    }
    if (this.isLogin) {
      this.messageunread()
    }
  },
  methods: {
    getFace(member) {
      if (member && member.gender === '男') {
        return member.faceImg && member.faceImg.url ? member.faceImg.url : 'https://static.dasdt.cn/images/nan.png'
      } else if (member && member.gender === '女') {
        return member.faceImg && member.faceImg.url ? member.faceImg.url : 'https://static.dasdt.cn/images/nv.png'
      } else {
        return member.faceImg && member.faceImg.url ? member.faceImg.url : 'https://static.dasdt.cn/images/deful.png'
      }
    },
    async messageunread() {
      const {data} = await messageunread()
      this.messageNum = data[0]
    },
    // 退出
    quit() {
      localStorage.removeItem('x-token')
      this.$store.commit('getToken', null)
      localStorage.removeItem('info')
      localStorage.removeItem('area')
      this.$store.commit('userInfo', {})
      this.$message({
        message: '退出成功',
        type: 'success'
      });
      if (this.$route.path === '/') {
        this.$router.go(0)
      } else {
        this.$router.push('/')
      }
    },
    changeCity(el) {
      this.city = el
    },
    blur() {
      this.borders = '#DCDFE6'

    },
    open() {
      this.isShow2 = true
    },
    goFeedback() {
      this.$router.push('/Feedback')
    },
    goPrivacy() {
      this.$router.push('/Privacy')
    },
    goPersonCenter() {
      this.$router.push('/PersonalCenter')
    },
    Selected(el) {
      this.current = el
      if (el === 1) {
        this.$router.push('/')
      } else if (el === 2) {
        this.$router.push('position')
      } else if (el === 4) {
        this.$router.push('/course')
      } else if (el === 3) {
        if(!this.isLogin) {

          this.isShow2 = true
          return
        }
        this.$router.push('/MessageCenter')
      }else if(el === 5) {
        this.$router.push('/recommendedPositions')
      }
    },
    goHome() {
      this.$router.push('/')
    },
    goMessageCenter() {
      if (process.env.NODE_ENV === 'development') {
        window.open('https://rc-company.51shanjian.cn/')
      } else {
        window.open('https://company.dasdt.cn/')
      }
    }
  }
}
</script>

<style lang="scss">
.popover {
  display: flex;
  flex-direction: column;

  .item:not(:last-child) {
    border-bottom: 1px solid #E6E6E6;
  }

  .item {
    cursor: pointer;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      gap: 6px;

      .icon {
        width: 24px;
        height: 24px;
      }

      span {
        color: #333333;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .icon2 {
      width: 20px;
      height: 20px;
    }
  }
}

.headers {
  z-index: 2000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #1E65E7;
  height: 55px;

  .el-badge__content {
    border: none !important;
  }

  .el-badge__content.is-fixed {
    right: 8px;
  }


  .content {
    height: 100%;
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .right {
      display: flex;
      align-items: center;
      gap: 48px;

      .login {
        cursor: pointer;
        width: 79px;
        height: 28px;
        border-radius: 4px;
        border: 1px solid rgba($color: #ffffff, $alpha: 0.4);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
      }

      .userInfo {
        display: flex;
        align-items: center;
        gap: 8px;
        cursor: pointer;

        img {
          border-radius: 50%;
          width: 24px;
          height: 24px
        }

        span {
          color: #FFFFFF;
          font-family: PingFang SC;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;

        }
      }

      .nav {
        display: flex;
        align-items: center;
        gap: 32px;
        position: relative;

        .messageNum {
          position: absolute;
          top: -6px;
          right: -8px;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background: #FD5056;
          border: 1px solid #3076FF;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #FFFFFF;
          font-size: 11px;
        }

        .current {
          font-weight: 600 !important;
          border-bottom: 2px solid #fff;
        }

        .item {
          padding-bottom: 3px;
          cursor: pointer;
          color: #fff;
          font-size: 14px;

        }

      }
    }

    .logoOrAdress {
      display: flex;
      align-items: center;
      //gap: 20px;

      .logo {
        cursor: pointer;
        width: 50px;
        height: 33px;
      }

      .adress {
        cursor: pointer;
        border-radius: 8px;
        padding: 5px 8px;
        //width: 84px;
        //height: 32px;

        border: 1px solid rgba($color: #ffffff, $alpha: 0.12);
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
          background: rgba($color: #ffffff, $alpha: 0.12);;
        }

        .iocn1 {
          width: 14px;
          height: 14px;
        }

        span {
          font-weight: 400;
          font-size: 14px;
          color: #ffffff;
          margin-left: 2px;
          margin-right: 8px;
        }

        .icon2 {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
</style>
