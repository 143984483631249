<template>
  <div class="home">
    <Header/>
    <Searchs/>
    <div class="homeConent">
      <banner/>
      <div class="the_content">
        <div class="title">职位推荐</div>
        <div class="screenWrap">
          <div class="left">
            <div class="item" @click="select(1)" :class="{current: current === 1}">热招职位</div>
            <div class="item" @click="select(2)" :class="{current: current === 2}">最新发布</div>
            <div class="item" @click="select(3)" :class="{current: current === 3}">推荐职位</div>
          </div>
        </div>
        <div class="AppointmentWrap" style="align-items: flex-start;min-height: 400px" v-loading="loading"
             element-loading-text="加载中"
             element-loading-spinner="el-icon-loading"
             element-loading-background="transparent">
          <div class="Item" v-for="(item, index) in appointmentList" :key="index">
            <positionItem :appointmentInfo="item" />
          </div>
        </div>
        <div class="ViewMore" @click="goposition">查看更多</div>
        <div class="title">精选课程</div>
        <div class="screenWrap">
          <div class="left">
            <div class="item" @click="select2(item.classifyId)" v-for="item in classifyList" :key="item.classifyId"
                 :class="{current: current2 === item.classifyId}">{{ item.classifyName }}
            </div>
          </div>
        </div>

        <div class="curriculumList">
          <div class="item" v-for="(item,index) in curriculumList" :key="index">
            <curriculumInfo :info="item" :current1="current2" :current2="current1"/>
          </div>
        </div>
        <div class="ViewMore" @click="Jump">查看更多</div>
      </div>
      <Footer/>
    </div>
    <floatRight/>

  </div>
</template>

<script>
import Header from '@/components/headers/index.vue'
import Searchs from './search.vue';
import positionItem from "@/views/home/positionItem.vue";
import banner from './banner.vue';
import curriculumInfo from './curriculumInfo.vue'
import Footer from '@/components/footer/index.vue'
import floatRight from '@/components/floatRight/vue.vue'
import {classify, records} from "@/api/course";
import {pageHotVO, pageSellVO,} from "@/api/position";

export default {
  components: {Header, Searchs, banner, positionItem, curriculumInfo, Footer, floatRight},
  data() {
    return {
      loading: false,
      current: 1,
      current1: 2,
      current2: 1,
      appointmentList: [],
      curriculumList: [],
      classifyList: [],
      pagination: {
        number: 1,
        limit: 12
      },
      totalCount: 0
    }
  },
  computed: {
    param() {
      return {
        firstClassifyId: this.current2,
      }
    },
    areaId() {
      return this.$store.getters.area.id

    }
  },
  watch: {
    areaId() {
      this.getPageJobs()

    },
    param() {
      this.records()
    },
    pagination: {
      handler: function () {
        this.records()
      },
      deep: true
    },
  },
  created() {
    this.classify()
    this.getPageJobs()
  },
  mounted() {
    scrollTo(0, 0)
  },
  methods: {
    async pageSellVO() {
      this.appointmentList = []
      this.loading = true
      let params = {}
      // params.orderBy = [{'name':'id','direction':'DESC'}]
      params.state = 'PUBLISHED'
      if (this.$store.getters.area.id)  {
        params.areaSearch = this.areaId
      }
      params.auditState = 'SUCCESS'
      const res = await pageSellVO(params, {number: 1, limit: 9})
      if (res.data.length === 0) {
        this.pageSellVONoData()
        return
      }
      res.data.forEach(item => {
        if (item.jobTagList) {
          item.tagList = []
          item.tagList = [...item.tagList, ...item.jobTagList]
        }
        if (item.customJobTag) {
          item.tagList = [...item.tagList, ...item.customJobTag]
        }
        if(item.tagList&&item.tagList.length>3) {
          item.tagList = item.tagList.slice(0, 3)
        }
        if(item.tags&&item.tags.length>3) {
          item.tags = item.tags.slice(0, 3)
        }
      })
      this.appointmentList = res.data
      this.loading = false
    },
    async pageSellVONoData() {
      this.appointmentList = []
      this.loading = true
      let params = {}
      // params.orderBy = [{'name':'id','direction':'DESC'}]
      params.noCate = "1"
      params.state = 'PUBLISHED'
      if (this.$store.getters.area.id)  {
        params.areaSearch = this.areaId
      }
      params.auditState = 'SUCCESS'
      const res = await pageSellVO(params, {number: 1, limit: 9})
      res.data.forEach(item => {
        if (item.jobTagList) {
          item.tagList = []
          item.tagList = [...item.tagList, ...item.jobTagList]
        }
        if (item.customJobTag) {
          item.tagList = [...item.tagList, ...item.customJobTag]
        }
        if(item.tagList&&item.tagList.length>3) {
          item.tagList = item.tagList.slice(0, 3)
        }
        if(item.tags&&item.tags.length>3) {
          item.tags = item.tags.slice(0, 3)
        }
      })
      this.appointmentList = res.data
      this.loading = false
    },
    async getPageJobs() {
      this.appointmentList = []
      this.loading = true
      let params = {}
      params.orderBy = [{'name':'hotNumber','direction':'DESC'}]
      if (this.$store.getters.area.id)  {
        params.areaSearch = this.areaId
      }
      params.state = 'PUBLISHED'
      params.auditState = 'SUCCESS'
      const res = await pageHotVO(params, {number: 1, limit: 9})
      res.data.forEach(item => {
        if (item.jobTagList) {
          item.tagList = []
          item.tagList = [...item.tagList, ...item.jobTagList]
        }
        if (item.customJobTag) {
          item.tagList = [...item.tagList, ...item.customJobTag]
        }
      })
      this.appointmentList = res.data
      this.loading = false
    },
    async getPageJobsUpdateTime() {
      this.appointmentList = []
      this.loading = true
      let params = {}
      params.orderBy = [{'name':'updateTime','direction':'DESC'}]
      params.state = 'PUBLISHED'
      if (this.$store.getters.area.id)  {
        params.areaSearch = this.areaId
      }
      params.auditState = 'SUCCESS'
      const res = await pageHotVO(params, {number: 1, limit: 9})
      res.data.forEach(item => {
        if (item.jobTagList) {
          item.tagList = []
          item.tagList = [...item.tagList, ...item.jobTagList]
        }
        if (item.customJobTag) {
          item.tagList = [...item.tagList, ...item.customJobTag]
        }
      })
      this.appointmentList = res.data
      this.loading = false
    },
    goposition() {
      this.$router.push('/recommendedPositions')
    },
    select(el) {
      if (el === 1) {
        this.getPageJobs()
      } else if (el === 2) {
        this.getPageJobsUpdateTime()
      } else {
        this.pageSellVO()
      }
      this.current = el
    },
    select2(el) {
      if(el === 1) {
        this.current1 = 2
      } else if(el === 10) {
        this.current1 = 11
      }else if(el === 32) {
        this.current1 = 33
      }else{
        this.current1 = 9
      }
      this.current2 = el
    },
    // 获取课程分类
    async classify() {
      const {data} = await classify()
      this.classifyList = data
      await this.records()
    },
    // 课程列表
    async records() {
      const res = await records(this.param, this.pagination)
      this.curriculumList = res.data
    },
    Jump() {
      this.$router.push('/course')
    }
  },

}
</script>
<style lang="scss">
.home {

  .homeConent {
    background: #F5F5F6;
    width: 100%;
    overflow: hidden;

    .the_content {
      width: 1200px;
      margin: 0 auto;

      .curriculumList {
        margin-top: 16px;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        align-items: center;

        .item {
          width: 287.75px;
          height: 294px;
          border-radius: 8px;
          border: 1px solid #FFFFFF;
          background: #FFFFFF;
          overflow: hidden;
        }
      }

      .ViewMore {
        margin: 32px auto;
        width: 390px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #185BE0;
        color: #185BE0;
        font-size: 14px;
        font-weight: 400;
        border-radius: 4px;
        cursor: pointer;
      }

      .screenWrap {
        margin-top: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba($color: #1F1F1F14, $alpha: 0.08);

        .left {
          display: flex;
          align-items: center;
          gap: 32px;

          .current {
            color: #185BE0 !important;
            border-bottom: 2px solid #185BE0;
          }

          .item {
            height: 32px;
            cursor: pointer;
            color: rgba($color: #1F1F1F, $alpha: 0.6);
            font-size: 16px;
            font-weight: 400;
          }
        }
      }

      .title {
        font-size: 24px;
        font-weight: 500;
        color: #1F1F1F;
      }
    }
  }

}

</style>
