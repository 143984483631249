<template>
  <div class="curriculumInfo" @click="goDetails(info.courseId)">
    <div class="head">
      <div class="name" :style="{color: fontColor}">{{ info?.courseName }}</div>
      <img v-if="current1 == 1&&current2 == 2" :src="imgList1[Math.round(Math.random()*3)]" alt="">
      <img v-else-if="current1 == 1&&current2 == 3" :src="imgList2[Math.round(Math.random()*2)]" alt="">
      <img v-else-if="current1 == 1&&current2 == 4" :src="imgList3[Math.round(Math.random()*2)]" alt="">
      <img v-else-if="current1 == 1&&current2 == 5" :src="imgList4[Math.round(Math.random()*3)]" alt="">
      <img v-else-if="current1 == 1&&current2 == 6" :src="imgList5[Math.round(Math.random())]" alt="">
      <img v-else-if="current1 == 1&&current2 == 7" :src="imgList6[Math.round(Math.random()*2)]" alt="">
      <img v-else-if="current1 == 1&&current2 == 8" :src="imgList7[Math.round(Math.random()*2)]" alt="">
      <img v-else-if="current1 == 10&&current2 == 11" :src="imgList8[Math.round(Math.random()*3)]" alt="">
      <img v-else-if="current1 == 10&&current2 == 12" :src="imgList9[Math.round(Math.random()*3)]" alt="">
      <img v-else-if="current1 == 10&&current2 == 13" :src="imgList10[Math.round(Math.random()*2)]" alt="">
      <img v-else-if="current1 == 10&&current2 == 146" :src="imgList11[Math.round(Math.random()*2)]" alt="">
      <img v-else-if="current1 == 14" :src="imgList12[Math.round(Math.random()*3)]" alt="">
      <img v-else-if="current1 == 22" :src="imgList13[Math.round(Math.random()*3)]" alt="">
      <img v-else-if="current1 == 31" :src="imgList14[Math.round(Math.random()*3)]" alt="">
      <img v-else-if="current1 == 32&&current2 == 33" :src="imgList15[Math.round(Math.random()*3)]" alt="">
      <img v-else-if="current1 == 32&&current2 == 35" :src="imgList12[Math.round(Math.random()*3)]" alt="">
      <img v-else src="https://static.dasdt.cn/images/curriculum5.png" alt="">

    </div>
    <div class="infoWrap">
      <div class="name">{{ info?.courseName }}</div>
      <!--            <div class="name">售前企业网络考试券H19-366</div>-->
      <div class="timer" v-if="info?.planCourseTime !== ''">开课时间: {{ info?.planCourseTime }}</div>
      <div class="price" v-if="info?.price!=='' && info?.price!=='0'"><span>￥</span>{{ info?.price }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      default: () => {
      }
    },
    current1: {
      type: Number || String,
      default: 1
    },
    current2: {
      type: Number || String,
      default: 2
    }
  },
  data() {
    return {
      imgList1: [
        'https://static.dasdt.cn/images/huawei1.png',
        'https://static.dasdt.cn/images/huawei2.png',
        'https://static.dasdt.cn/images/huawei3.png',
        'https://static.dasdt.cn/images/huawei4.png'
      ],
      imgList2: [
        'https://static.dasdt.cn/images/userCenter/cover/cover1.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover2.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover3.png',
      ],
      imgList3: [
        'https://static.dasdt.cn/images/userCenter/cover/cover1.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover4.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover3.png',
      ],
      imgList4: [
        'https://static.dasdt.cn/images/userCenter/cover/cover3.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover5.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover6.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover7.png',
      ],
      imgList5: [
        'https://static.dasdt.cn/images/userCenter/cover/cover8.png'
      ],
      imgList6: [
        'https://static.dasdt.cn/images/userCenter/cover/cover9.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover10.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover11.png',
      ],
      imgList7: [
        'https://static.dasdt.cn/images/userCenter/cover/cover12.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover13.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover14.png',
      ],
      imgList8: [
        'https://static.dasdt.cn/images/userCenter/cover/cover15.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover16.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover17.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover18.png',
      ],
      imgList9: [
        'https://static.dasdt.cn/images/userCenter/cover/cover19.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover20.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover21.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover22.png',
      ],
      imgList10: [
        'https://static.dasdt.cn/images/userCenter/cover/cover23.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover24.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover25.png',
      ],
      imgList11: [
        'https://static.dasdt.cn/images/userCenter/cover/cover26.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover27.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover28.png',
      ],
      imgList12: [
        'https://static.dasdt.cn/images/userCenter/cover/cover29.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover30.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover31.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover32.png',
      ],
      imgList13: [
        'https://static.dasdt.cn/images/userCenter/cover/cover33.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover34.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover35.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover36.png',
      ],
      imgList14: [
        'https://static.dasdt.cn/images/userCenter/cover/cover37.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover38.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover39.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover40.png',
      ],
      imgList15: [
        'https://static.dasdt.cn/images/userCenter/cover/cover41.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover42.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover43.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover44.png',
      ],
      imgList16: [
        // 'https://static.dasdt.cn/course/img34.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover45.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover46.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover47.png',
        'https://static.dasdt.cn/images/userCenter/cover/cover48.png',
      ],
    }
  },
  computed: {
    fontColor() {
      return '#fff'
      // if (this.current1 == 1 && this.current2 == 2) {
      //   return '#323232'
      // } else {
      //
      // }
    }
  },
  methods: {
    goDetails(id) {
      if (this.$route.path === "/courseDetals") {// 当前页重定向当前页
        window.location.href = location.origin + location.pathname + "?id=" + id
      } else {
        this.$router.push(`/courseDetals?id=${id}`)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.curriculumInfo {
  cursor: pointer;

  .infoWrap {
    margin: 14px 16px;

    .price {
      margin-top: 8px;
      color: #FD5056;
      font-size: 24px;
      font-weight: 500;
      line-height: 24px;

      span {
        font-size: 14px;
        font-weight: 500;
        color: #FD5056;
      }
    }

    .timer {
      color: rgba($color: #1F1F1F, $alpha: 0.6);
      font-size: 14px;
      font-weight: 400;
      margin-top: 8px;
    }

    .name {

      height: 44px;
      font-size: 16px;
      color: #1F1F1F;
      line-height: 22px;
      font-weight: 500;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      line-clamp: 2; /* 同样设置显示的行数，非Webkit内核的浏览器使用此属性 */
      -webkit-line-clamp: 2; /* Webkit内核（如Chrome和Safari）使用此属性 */
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .head {
    height: 160px;
    width: 100%;
    position: relative;

    .name {
      position: absolute;
      top: 70px;
      left: 10px;
      font-size: 16px;
      width: 50%;
      color: #fff;
      line-height: 22px;
      font-weight: 800;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      line-clamp: 2; /* 同样设置显示的行数，非Webkit内核的浏览器使用此属性 */
      -webkit-line-clamp: 2; /* Webkit内核（如Chrome和Safari）使用此属性 */
      overflow: hidden;
      text-overflow: ellipsis;
    }

    img {
      width: 100%;
      height: 160px;
    }
  }

}
</style>
