import axios from 'axios'
import {Message} from 'element-ui'
// import {getToken} from '@/utils/auth'
import store from '@/store/index'
// import qs from "qs";
const service = axios.create({
    baseURL: process.env.VUE_APP_API, // url = base url + request url
    withCredentials: false, // send cookies when cross-domain requests
    timeout: 30000 // request timeout
})

service.interceptors.request.use(
    config => {
        config.headers['x-token'] = store.getters.token
        // }
        // if (config.proxyMode) {
        //     config.baseURL = config.proxyMode
        // }
        // if (config.method === 'get') {
        //     config.paramsSerializer = function(params) {
        //         return qs.stringify(params, {arrayFormat: 'repeat'})
        //     }
        // }
        return config
    },
    error => {
        // do something with request error
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
        // if the custom code is not 20000, it is judged as an error.
        if (response.headers['content-type'] === 'application/octet-stream;charset=utf-8') {
            return res
        }
        // return res
        if (res.code !== 'A00000' && res.code !== 200) {
            if (res.code === 401) { // token失效
                // store.dispatch('user/clearUserInfo') // 清除用户信息
                // window.location.reload()
            }else if(res.code === 'A00003') {
                console.log(res)
            } else {
                console.log(res)
                Message({
                    message: res.exception,
                    type: 'error',
                    duration: 2 * 1000
                })
                return Promise.reject(res)
            }
        } else {
            return res
        }
    },
    error => {
        // Message.closeAll()
        if (error.response || error.message.cancel) {
            switch (error.response.status) {
                case 401:
                    localStorage.removeItem('x-token')
                    localStorage.removeItem('info')
                    localStorage.removeItem('area')
                    store.commit('getToken', '0')
                    store.commit('userInfo', {})
                    store.commit('showLogin', true)
                    return
            }
        } else {
            Message({
                message: error.message,
                type: 'error',
                duration: 2 * 1000
            })
        }
        return Promise.reject(error)
    }
)

export default service
