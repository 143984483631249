<template>
  <div class="loginss">
    <div class="sssssss">
      <el-dialog
          :visible.sync="value"
          width="793px"
          :modal-append-to-body='false'
          :before-close="handleClose">
        <div class="loginDialog">
          <div class="Illustration">
            <div class="descWrap">
              <div class="item">
                <div class="font_1">找工作上云梯</div>
                <div class="font_2">50w+职位任您选择</div>
              </div>
              <div class="item">
                <div class="font_1">高薪名企</div>
                <div class="font_2">职位任您选择</div>
              </div>
            </div>
            <img src="https://static.dasdt.cn/images/Illustration.png">
          </div>
          <div class="logins" :style="{'--border': borders}">
            <div class="title">欢迎登录</div>
            <div class="desc">首次手机验证码登录将自动注册账号</div>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="ruleForm">
              <el-form-item prop="phone">
                <el-input placeholder="请输入你的手机号" maxlength="11" style="height: 50px" @focus="focus" @blur="blur"
                          v-model="ruleForm.phone">
                  <template slot="prepend">+86</template>
                </el-input>
              </el-form-item>
              <el-form-item prop="code" style="position: relative">
                <el-input placeholder="请输入短信验证码" maxLength="4" style="height: 50px" v-model="ruleForm.code"></el-input>

                <div class="code" @click="findPasswordgetVert" :style="{'pointer-events': Disabled ? 'none' : 'auto'}">
                  {{ codeValue }}
                </div>
              </el-form-item>
            </el-form>
            <el-checkbox style="margin-left: -90px" v-model="checked">
              <div class="check">
                我已阅读并同意 <a style="color: #1A81F1" @click="goxieyi(1)">《服务协议》</a>和<a style="color: #1A81F1" @click="goxieyi(2)">《隐私协议》</a>
              </div>
            </el-checkbox>
            <div class="submit" @click="login('ruleForm')">登录/注册</div>
          </div>
        </div>
      </el-dialog>

    </div>
    <desire v-model="isShow"/>

  </div>
</template>
<script>
import {getCurrentResume, login, getCourseDetails} from "@/api/currency";
import desire from "@/components/desire/index.vue";

export default {
  components: {desire},
  props: {
    value: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      Disabled: false,
      isShow: false,
      checked: false,
      dialogVisible: false,
      codeValue: '获取验证码',
      timer: null,
      ruleForm: {
        phone: '',
        code: ''
      },
      borders: '#DCDFE6',
      rules: {
        phone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {
            pattern:
                /^1(1[0-9]|3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
            message: "请输入正确的手机号码",
            trigger: 'blur'
          }
        ],
        code: [
          {required: true, message: '请输入验证码', trigger: 'blur'},
          {min: 4, max: 4, message: '请输入正确的验证码', trigger: 'blur'},
          {
            pattern:
                /^\d{4}$/,
            message: "请输入正确的验证码",
            trigger: 'blur'
          }
        ]
      },
    }
  },
  watch: {
    isShow(newVal) {
      if (newVal === false) {
        this.$router.go(0)
      }
    },
  },

  methods: {
    goxieyi(index) {
      console.log(index)
      this.$router.push({
        path: '/agreement',
        query: {
          id: index
        }
      })
    },
    // 登录
    async login(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if(this.checked) {
            const res = await login({client: 'USER_PC', ...this.ruleForm})
            if (res.code === 'A00000') {
              localStorage.setItem('x-token', res.data[0])
              await this.$store.commit('getToken', res.data[0])
              await this.getCurrent()
            }
          }else {
            this.$message({
              message: '请勾选同意服务协议',
              type: 'error'
            });
          }

        } else {
          console.log('error submit!!');
          return false;
        }
      })
    },
    // 获取用户信息
    async getCurrent() {
      const {data} = await getCurrentResume()
      console.log(data[0])
      await this.$store.commit('userInfo', data[0])
      this.$emit('input', false)
      console.log(data[0])
      // this.dialogVisible = false
      if (data[0].cateIds.length === 0) {
        this.isShow = true
      } else {
        this.$router.go(0)
      }
      this.$message({
        message: '登录成功',
        type: 'success'
      });

    },
    // 发送验证码
    async findPasswordgetVert() {
      const phone = /^1(1[0-9]|3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/
      if (!phone.test(this.ruleForm.phone)) {
        this.$message({
          message: '请输入正确的手机号码',
          type: 'error'
        });
        return
      }
      this.Disabled = true;
      const {data} = await getCourseDetails(this.ruleForm.phone)
      console.log(data)
      this.handleTime()
    },
    handleTime() {

      this.codeValue = "60s";
      this.timer = setInterval(() => {
        let nums = parseInt(this.codeValue);
        nums--;
        this.codeValue = `${nums}s`;
      }, 1000);
      setTimeout(() => {
        clearInterval(this.timer);
        this.timer = null;
        this.Disabled = false;

        this.codeValue = "获取验证码";
      }, 60000);
    },
    focus() {
      this.borders = '#409EFF'
    },
    blur() {
      this.borders = '#DCDFE6'

    },
    open() {
      // this.dialogVisible = true
      this.$emit('input', true)

    },
    handleClose() {
      this.$store.commit('showLogin', false)
      this.$emit('input', false)
      // this.dialogVisible = false
    },
  }
}
</script>



<style  lang="scss">
.loginss {
  .sssssss{
    .el-dialog__body {
      padding: 0 !important;
    }

    .el-dialog__header {
      padding: 0 !important;
    }
  }
  .loginDialog {
    display: flex;
    align-items: center;
    height: 455px;
    border-radius: 12px;
    overflow: hidden;

    .submit {
      margin-top: 30px;
      width: 402px;
      height: 50px;
      border-radius: 8px;
      background: #1A81F1;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
    }

    .ruleForm {
      margin-top: 36px;
      width: 402px;

      .code {
        display: flex;
        justify-content: flex-end;
        width: 90px;
        background: #fff;
        color: #1A81F1;
        font-size: 16px;
        font-weight: 400;
        position: absolute;
        right: 8px;
        top: 4px;
        cursor: pointer;
      }

      .el-checkbox {
        .check {
          margin-top: 10px;
          color: #808080;
          font-size: 14px;
          font-weight: 400;

          a {
            color: #1A81F1;
            cursor: pointer;
          }
        }
      }

      .el-input__inner {
        height: 50px !important;
      }
    }

    .el-input-group__append, .el-input-group__prepend {
      background: #fff !important;
    }

    .el-input-group--append .el-input__inner, .el-input-group__prepend {
      //border: var(--border);
      border-top: 1px solid var(--border);
      border-left: 1px solid var(--border);
      border-bottom: 1px solid var(--border);
    }

    .logins {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .desc {
        margin-top: 12px;
        color: #ADADAD;
        font-size: 14px;
        font-weight: 400;
      }

      .title {
        margin-top: 55px;
        color: #333333;
        font-size: 24px;
        font-weight: 600;
      }
    }

    .Illustration {
      background: linear-gradient(to bottom left, #DDEDFF, #ffffff);
      width: 304px;
      height: 100%;

      img {
        width: 293px;
        height: 294px;
      }

      .descWrap {
        margin-top: 36px;
        margin-left: 38px;
        display: flex;
        flex-direction: column;
        gap: 26px;

        .item {
          display: flex;
          flex-direction: column;
          gap: 4px;

          .font_1 {
            color: #1D4799;
            font-size: 18px;
            font-weight: 600;
          }

          .font_2 {
            color: #6793C3;
            font-weight: 400;
            font-size: 14px;
          }
        }
      }
    }
  }

}
</style>
