<template>
  <div id="app">
    <div class="backgrounds" :style="{top: routerName === 'Home'? '-25px':'55px',display: routerName === 'agreement' ? 'none':''}"></div>
    <router-view/>
  </div>
</template>
<script>
export default {
  created() {
    console.log('app created', this.$route.name)
  },
  computed: {
    routerName() {
      return this.$route.name
    }
  }
};
</script>
<style lang="scss">
#app {
  position: relative;
  .backgrounds{
    position: absolute;
    background-image: url('https://static.dasdt.cn/images/bg0902.png');
    background-size: 100% 100%;
    top: -25px;
    width: 100%;
    height: 380px;
  }
  .el-dialog{
    display: flex;
    flex-direction: column;
    margin:55px 0 0 0 !important;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    max-height:calc(100% - 30px);
    max-width:calc(100% - 30px);
  }
  .el-dialog .el-dialog__body{
    flex:1;
    overflow: auto;
  }
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  input[type="number"]{
    -moz-appearance: textfield;
  }
  input::-webkit-credentials-auto-fill-button {
    display: none !important;
    visibility: hidden;
    pointer-events: none;
    position: absolute;
    right: 0;
  }

}

</style>
