<template>
    <div class="searchWrap">
     <div class="content">
        <div class="inputSearch">
            <img src="https://static.dasdt.cn/images/search.png" alt="">
            <div class="contentInput">
                <el-input  @focus="focus" @blur="goPostion" v-model="input" @keyup.enter.native="goPostion" placeholder="大家都在搜：JAVA"></el-input>
            </div>
            <div class="submit" @click="goPostion">搜索</div>
<!--          <div class="result" v-show="show">-->
<!--            <div class="resultWrap">-->
<!--              <p v-html="brightenKeyword('算法',input)"></p>-->
<!--              <p v-html="brightenKeyword('算法工程师',input)"></p>-->
<!--              <p v-html="brightenKeyword('AI算法',input)"></p>-->
<!--              <p v-html="brightenKeyword('推荐算法',input)"></p>-->
<!--              <p v-html="brightenKeyword('图像算法',input)"></p>-->
<!--              <p v-html="brightenKeyword('视觉算法',input)"></p>-->
<!--              <p v-html="brightenKeyword('音频算法',input)"></p>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <div class="Popular" v-if="hotWord">
            <div class="title">热门职位：</div>
            <div class="list">
                <div class="item" v-for="item in hotWord" :key="item.id" @click="goToJob(item.job)">{{ item.job }}</div>
<!--                <div class="item">UI设计</div>-->
<!--                <div class="item">Python</div>-->
<!--                <div class="item">系统运维工程师</div>-->
<!--                <div class="item">软件测试</div>-->
<!--                <div class="item">硬件测试</div>-->
<!--                <div class="item">新媒体运营</div>-->
<!--                <div class="item">数据挖掘</div>-->
            </div>
        </div>
     </div>
    </div>
</template>

<script>
import { getHotWordJobs } from "@/api/position";
export default{
    data() {
        return{
            input: '',
            value:1,
            options: [
             {
                label: '实习',
                value:1,
             },
             {
                label: '校招',
                value:2,
             },
             {
                label: '社招',
                value:3,
             },
            ],
            show: false,
            hotWord: []
        }
    },
    created() {
        this.getHotWordJobs()
    },
  computed: {
    areaId() {
      return this.$store.getters.area.id

    }
  },
  watch: {
    areaId() {
      this.getHotWordJobs()

    },
  },
    methods: {
        goPostion() {
            this.$router.push(`/position?searchParam=${this.input}`)
        },
        goToJob(job) {
            this.$router.push(`/position?job=${job}`)
        },
        async getHotWordJobs() {
            this.loading = true
            let params = {}
            params.auditState = 'SUCCESS'
          if (this.$store.getters.area.id)  {
            params.areaSearch = this.areaId
          }
            const res = await getHotWordJobs(params, {number: 1, limit: 5})
            this.hotWord = res.data
            this.loading = false
        },
        focus() {
          this.show = true
        },
        blur() {
          this.show = false
        },
        // 高亮搜索词
        brightenKeyword(val, keyword) {
          const Reg = new RegExp(keyword, 'i');
          let res = '';
          if (val) {
            res = val.replace(Reg, `<span style="color: #185BE0;">${keyword}</span>`);
            return res;
          }
        },
    }
}
</script>

<style lang="scss" >
.searchWrap{
    width: 100%;
    background: #ffffff;
    .content{
        width: 990px;
        margin: 0 auto;
      .highlight {
        background-color: yellow;
      }
      .result{
        position: absolute;
        top: 45px;
        left: 0;
        width: 815px;
        min-height: 272px;
        border-radius: 8px;
        background: #fff;
        border: 1px solid #EDEDED;
        box-shadow: 0 4px 14px 0 rgba($color: #000000, $alpha: 0.1);
        z-index: 999;
        .resultWrap{
          padding: 0 20px;
          p{
            cursor: pointer;
            color: #333333;
            font-size: 14px;
            line-height: 36px;
            font-weight: 400;
          }
        }
      }
        .Popular{
            margin-top: 16px;
            display: flex;
            align-items: center;
            gap: 20px;
            //margin-bottom: 24px;
            .title{
              z-index: 9;
                margin-left: 12px;
                font-size: 14px;
                font-weight: 400;
                color: #1F1F1F;
            }
            .list{
              z-index: 9;
              display: flex;
                align-items: center;
                gap: 24px;
                .item{
                    cursor: pointer;
                    color: #185BE0;
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }

    }
}
</style>
