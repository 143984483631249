<template>
  <div class="floatRightWrap">
    <div class="floatRight">
      <div class="top">
<!--        <div class="item" v-if="show1" @mouseover="handleOver(1)">-->
<!--          <img src="https://static.dasdt.cn/images/link.png">-->
<!--        </div>-->
<!--        <div v-else @mouseleave="handleOut(1)" @click="Jump(1)" class="item">-->
<!--          <img src="https://static.dasdt.cn/images/link2.png">-->
<!--          <div class="name">我的简历</div>-->
<!--        </div>-->
        <div v-if="show2" @mouseover="handleOver(2)" class="item">
          <img src="https://static.dasdt.cn/images/phone.png">
        </div>
        <div v-else @mouseleave="handleOut(2)" class="item" @click="Jump(2)">
          <img src="https://static.dasdt.cn/images/phone3.png">
          <div class="name">我的简历</div>
        </div>
        <div v-if="show3" @mouseover="handleOver(3)" class="item">
          <img src="https://static.dasdt.cn/images/weizhi.png">
        </div>
        <div v-else @mouseleave="handleOut(3)" class="item" @click="Jump(3)">
          <img src="https://static.dasdt.cn/images/weizhi2.png">
          <div class="name">面试记录</div>
        </div>
        <div v-if="show4" @mouseover="handleOver(4)" class="item">
          <img src="https://static.dasdt.cn/images/star.png">
        </div>
        <div v-else @mouseleave="handleOut(4)" class="item" @click="Jump(4)">
          <img src="https://static.dasdt.cn/images/star4.png">
          <div class="name">收藏记录</div>
        </div>
      </div>
      <div class="backTop" @click="backToTop" @mouseleave="handleOut(5)" @mouseover="handleOver(5)">
        <img src="https://static.dasdt.cn/images/backTop.png">
        <div v-if="show5" class="name">回到顶部</div>
      </div>
    </div>
    <logins v-model="isShow2"/>

  </div>
</template>
<script>
import store from "@/store";
import logins from "@/components/login/index.vue";

export default {
  components: {logins},
  data() {
    return {
      show1: true,
      show2: true,
      show3: true,
      show4: true,
      show5: false,
    }
  },
  computed: {
    isShow2() {
      return this.$store.getters.showLogin
    },
  },
  created() {
  },
  mounted() {
  },
  methods: {
    Jump(el) {
      if(localStorage.getItem('x-token')===null) {
        store.commit('showLogin', true)
        return
      }
      if (el === 1|| el===2) {
        this.$router.push('/PersonalCenter')
      } else if (el === 3) {
        this.$router.push('/PersonalCenter?id=2')
      } else if (el === 4) {
        this.$router.push('/PersonalCenter?id=3')
      }
    },
    backToTop() {
      const upRoll = setInterval(() => {
        const top = document.documentElement.scrollTop; // 每次获取页面被卷去的部分
        const speed = Math.ceil(top / 10); // 每次滚动多少 （步长值）
        if (document.documentElement.scrollTop > 0) {
          document.documentElement.scrollTop -= speed; // 不在顶部 每次滚动到的位置
        } else {
          clearInterval(upRoll); // 回到顶部清除定时器
        }
      }, 20);
    },
    handleOver(el) {
      if (el === 1) {
        this.show1 = false
      } else if (el === 2) {
        this.show2 = false
      } else if (el === 3) {
        this.show3 = false
      } else if(el === 4){
        this.show4 = false
      }else{
        this.show5 = true
      }
    },
    handleOut(el) {
      if (el === 1) {
        this.show1 = true
      } else if (el === 2) {
        this.show2 = true
      } else if (el === 3) {
        this.show3 = true
      } else if(el === 4) {
        this.show4 = true
      }else{
        this.show5 = false
      }
    }
  }
}
</script>
<style scoped lang="scss">
.floatRight {
  display: flex;
  flex-direction: column;
  gap: 12px;

  .backTop {
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #185BE0;
    border-radius: 100px;
    cursor: pointer;
    position: relative;

    .name {
      position: absolute;
      left: -73px;
      top: 10px;
      width: 68px;
      height: 22px;
      border-radius: 4px;
      background: #4E545F;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 14px;
      font-weight: 400;
    }

    img {
      width: 28px;
      height: 28px;
    }
  }

  .top {
    width: 44px;
    height: 160px;
    background: #fff;
    border-radius: 100px;
    box-shadow: 0 4px 12px 4px rgba($color: #000000, $alpha: 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 18px;

    .item {
      position: relative;

      .name {
        position: absolute;
        left: -84px;
        top: 0;
        width: 68px;
        height: 22px;
        border-radius: 4px;
        background: #4E545F;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
      }
    }

    img {
      cursor: pointer;
      width: 24px;
      height: 24px;
    }
  }
}
</style>
