<template>
  <div class="desire">
    <el-dialog
        lock-scroll
        :modal-append-to-body='false'
        title="期望职位"
        :visible.sync="value"
        width="1000px"
        :before-close="handleClose">
      <div class="wrapJobIntention">
        <div class="titles">请选择技能标签 <span>{{ selectedItems.length }}</span>/3</div>
        <div class="SelectedList">
          <div class="item" v-for="(item, index) in selectedItems" :key="index">{{ item.name }}</div>
        </div>
        <div class="border"></div>
        <div class="titles">请选择职位类型</div>
<!--        <div class="searchWrap">-->
<!--          <div class="key">搜索职位</div>-->
<!--          <el-input-->
<!--              class="inputs"-->
<!--              placeholder="请输入内容"-->
<!--              prefix-icon="el-icon-search"-->
<!--              v-model="input2">-->
<!--          </el-input>-->
<!--        </div>-->
        <div class="contentss">
          <div class="navigation">
            <div class="item" v-for="(item, index) in navList" :key="index" @click="selects(item.id, index)"
                 :class="{ active: item.id === activeId}">
              <div class="itemWrap">
                <span>{{ item.name }}</span>
                <img src="https://static.dasdt.cn/images/userCenter/selects.png" alt="">
              </div>
            </div>
          </div>
          <div class="menus">
            <div class="items" v-for="(item, index) in seMenuInfo.nodes" :key="index">
              <div class="name" style="cursor: pointer">{{ item.name }}</div>
              <div class="tagLists">
                <div class="tags" v-for="(its, indexs) in item.nodes" :key="indexs"
                     :class="{'current': groupIds.includes(its.id)}" @click="select(its.id, its.name)">{{ its.name }}
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="footers">
        <div class="left">
          <div class="key">已选职位：</div>
          <div class="list">
            <div class="item" v-for="(item, index) in selectedItems" :key="index">
              <span class="names">{{ item.name }}</span>
              <img class="delets" @click="eventDelete(item.id)" src="https://static.dasdt.cn/images/userCenter/delets.png">
            </div>
          </div>
        </div>
        <div class="btn" slot="footer">
          <div class="left" @click="tiaoguo">重置</div>
<!--          <div class="right2" v-if="groupIds.length === 0" @click="submit">保存(0/3)</div>-->
<!--          <div class="right2" v-if="groupIds.length >0" @click="submit">我选好了({{ groupIds.length }}/3)</div>-->
          <div class="right2" @click="submit">我选好了({{ groupIds.length }}/3)</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import categoryJob from '@/assets/categoryJob.json'
import {getCurrentResume} from "@/api/position";
import {updateCateIds} from "@/api/currency";

export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    }
  },
  watch: {
    value() {
      this.getCurrentResume()
    }
  },
  created() {
    // this.listByGroupEnum()
  },
  data() {
    return {
      input2: '',
      dialogVisible: false,
      list: [],
      groupIds: [],
      dv: '',
      activeIndex: 0,
      navList: categoryJob.data,
      activeId: 1,
      selectedItems: [],
      cateIds: [],
    }
  },
  computed: {
    seMenuInfo() {
      if (this.activeIndex === null) return [];
      return this.navList[this.activeIndex];
    }
  },
  methods: {
    async submit() {
      const {data} = await updateCateIds(this.groupIds, this.dv)
      console.log(data)
      this.$message({
        message: '提交成功',
        type: 'success'
      })
      this.$emit('input', false)
      this.$emit('wancheng', true)
    },
    async getCurrentResume() {
      let that = this
      that.selectedItems = []
      that.groupIds = []
      const {data} = await getCurrentResume()
      this.dv = data[0].dv
      this.cateIds = data[0]?.cateIds
      if (data[0].cateIds && data[0].cateIds.length > 0) {
        data[0].cateIds.map((item,index)=>{
          let arr = {}
          arr.id = item
          arr.name = data[0].cateIdsComment[index]
          that.selectedItems.push(arr)
          that.groupIds.push(item)
        })
      }
    },
    selects(id, index) {
      this.activeIndex = index
      this.activeId = id
    },
    tiaoguo() {
      this.selectedItems = []
      this.groupIds = []
    },
    handleClose() {
      this.$emit('input', false)
    },
    // 多选标签
    select(id, name) {
      let that = this
      if (that.groupIds.includes(id)) {
        that.eventDelete(id)
      } else {
        if (this.selectedItems.length >= 3) {
          this.$message({
            message: '最多只能选择3个',
            type: 'warning'
          })
          return
        }
        this.selectedItems.push({id:id, name:name})
        this.cateIds.push(id)
        this.groupIds.push(id)
      }
    },
    eventDelete(id) {
      let that = this
      let arr = that.selectedItems
      arr.map((item,index) => {
        if (item.id == id){
          that.selectedItems.splice(index, 1)
          that.groupIds = that.groupIds.filter(id => id != item.id)
          that.cateIds = that.cateIds.filter(id => id != item.id)
        }
      })
    },
  }
}
</script>


<style lang="scss">
.desire {

  .el-input__inner {
    border-radius: 8px !important;
  }

  .el-dialog__body {
    padding: 0 !important;
  }

  .footers {
    box-shadow: 0 0 8px 0 rgba($color: #000000, $alpha: 0.12);
    height: 64px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;

      .list {
        display: flex;
        align-items: center;
        gap: 10px;

        .item {
          display: flex;
          align-items: center;
          gap: 8px;
          height: 32px;
          line-height: 32px;
          padding: 0 14px;
          border-radius: 4px;
          border: 1px solid rgba($color: #000000, $alpha: 0.1);
          background: #F8F8F8;
          justify-content: center;

          .names {
            color: #333333;
            font-size: 14px;
          }

          .delets {
            width: 12px;
            height: 12px;
            cursor: pointer;
          }
        }
      }

      .key {
        color: #555555;
        font-size: 14px;
        font-weight: 400;
      }
    }

    .btn {
      margin-top: 30px;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      gap: 10px;

      .left {
        border: 1px solid #ADADAD;
        width: 140px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        font-size: 16px;
        color: #808080;
        cursor: pointer;
      }

      .right2 {
        width: 140px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        font-size: 16px;
        color: #FFFFFF;
        cursor: pointer;
        background: #3A76FF;
      }

      .right {
        width: 140px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        font-size: 16px;
        color: #FFFFFF;
        cursor: pointer;
        background: #E6E6E6;
      }
    }
  }

    .wrapJobIntention {
      padding: 0 24px;


      .contentss {
        margin-top: 14px;
        display: flex;
        gap: 20px;

        .menus {
          display: flex;
          flex-direction: column;
          gap: 18px;

          .items {
            display: flex;
            flex-direction: column;
            gap: 12px;

            .name {
              color: #000000;
              font-size: 16px;
              font-weight: 500;
            }

            .tagLists {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              gap: 8px;

              .current {
                background: rgba($color: #3A76FF1A, $alpha: 0.1) !important;
                color: #3A76FF !important;
              }

              .tags {
                cursor: pointer;
                min-width: 103px;
                height: 32px;
                padding: 0 27px;
                background: #F6F6F6;
                border-radius: 4px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #333333;
                font-size: 12px;
              }
            }
          }
        }

        .navigation {
          width: 162px;
          display: flex;
          flex-direction: column;

          .active {
            border-radius: 8px;
            background: #E8F2FE;

            .itemWrap {
              span {
                color: #3A76FF !important;
                font-weight: 600;
              }

              img {
                display: block !important;
              }
            }
          }

          .item {
            cursor: pointer;
            width: 150px;
            height: 42px;

            .itemWrap {
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 13px 13px 13px 22px;

              span {
                font-size: 14px;
                color: #333333;
              }

              img {
                width: 16px;
                height: 16px;
                display: none;
              }
            }
          }
        }
      }

      .searchWrap {
        margin: 14px 0;
        padding: 10px 16px;
        background: #F8F8F8;
        border-radius: 8px;
        display: flex;
        align-items: center;
        gap: 10px;

        .key {
          color: #555555;
          font-size: 14px;
        }

        .inputs {
          border-radius: 8px;
          width: 733px;
          height: 40px;
        }
      }

      .border {
        margin: 20px 0;
        border-bottom: 1px solid #E6E6E6;
      }

      .SelectedList {
        margin-top: 14px;
        display: flex;
        align-items: center;
        gap: 14px;

        .item {
          padding: 0 14px;
          height: 34px;
          line-height: 34px;
          border: 1px solid #568AFF;
          background: rgba($color: #1A81F11A, $alpha: 0.1);
          border-radius: 4px;
          color: #3A76FF;
          font-size: 14px;
          font-weight: 500;
          cursor: pointer;
        }

      }

      .titles {
        font-weight: 500;
        font-size: 16px;
        color: #1F1F1F;

        span {
          margin-left: 16px;
          font-size: 18px;
          color: #3A76FF;
        }
      }
    }
}
</style>
