import request from '@/utils/request'

// 发送验证码
export function getCourseDetails(phone) {
    return request({
        url: `/open/auth/sms-login-code/${phone}`,
        method: 'post',
    })
}

// 登录认证
export function login(query) {
    return request({
        url: `/open/auth/login/${query.client}/${query.phone}/${query.code}`,
        method: 'post',
    })
}

// 获取用户信息
export function current() {
    return request({
        url: `/user/current`,
        method: 'post',
    })
}

// 我的界面数据
export function personal() {
    return request({
        url: `/member/personal`,
        method: 'get',
    })
}

// 获取城市拼音数据
export function getMap() {
    return request({
        url: `/open/map/py`,
        method: 'get',
    })
}

// 获取热门城市
export function hotList() {
    return request({
        url: `/open/hotList`,
        method: 'get',
    })
}

// 根据经纬度获取城市
export function getCityBy(data) {
    return request({
        url: `/open/map/getCityBy`,
        method: 'get',
        params: data
    })
}

// 根据城市查询城市编码
export function getAreaByCity(city) {
    return request({
        url: `/open/getAreaByCity/${city}`,
        method: 'get',
    })
}

// 根据城市查询城市编码-模糊查询
export function getAreasByCity(city) {
    return request({
        url: `/open/getAreasByCity/${city}`,
        method: 'get',
    })
}

// 获取个人信息
export function getCurrentResume() {
    return request({
        url: `/member/getCurrentResume`,
        method: 'get',
    })
}

// 修改人才用户基础信息
export function updateMember(data, dv) {
    return request({
        url: `/member/${dv}`,
        method: 'put',
        data: data
    })
}

// 新增工作经历
export function memberWorking(data) {
    return request({
        url: `/member-working`,
        method: 'post',
        data: data
    })
}

// 修改工作经历
export function updateMemberWorking(data, dv) {
    return request({
        url: `/member-working/${dv}`,
        method: 'put',
        data: data
    })
}

// 删除工作经历
export function deleteMemberWorking(dv) {
    return request({
        url: `/member-working/${dv}`,
        method: 'patch',
    })
}

// 新增教育经历
export function memberEducation(data) {
    return request({
        url: `/member-education`,
        method: 'post',
        data: data
    })
}

// 编辑教育经历
export function updateMemberEducation(data, dv) {
    return request({
        url: `/member-education/${dv}`,
        method: 'put',
        data: data
    })
}

// 删除教育经历
export function deleteMemberEducation(dv) {
    return request({
        url: `/member-education/${dv}`,
        method: 'patch',
    })
}

// 新增项目经历
export function memberProject(data) {
    return request({
        url: `/member-project`,
        method: 'post',
        data: data
    })
}

// 删除项目经历
export function deleteMemberProject(dv) {
    return request({
        url: `/member-project/${dv}`,
        method: 'patch',
    })
}

// 修改项目经历
export function updateMemberProject(data, dv) {
    return request({
        url: `/member-project/${dv}`,
        method: 'put',
        data: data
    })
}

// 新增培训经历
export function addMemberRrain(data) {
    return request({
        url: `/member-train`,
        method: 'post',
        data: data
    })
}

// 修改培训经历
export function updateMemberRrain(data, dv) {
    return request({
        url: `/member-train/${dv}`,
        method: 'put',
        data: data
    })
}

// 删除培训经历
export function deleteMemberRrain(dv) {
    return request({
        url: `/member-train/${dv}`,
        method: 'patch',
    })
}

// 新增职业技能
export function addMemberSkill(data) {
    return request({
        url: `/member-skill`,
        method: 'post',
        data: data
    })
}

// 修改职业技能
export function updateMemberSkill(data, dv) {
    return request({
        url: `/member-skill/${dv}`,
        method: 'put',
        data: data
    })
}

// 删除职业技能
export function deleteMemberSkill(dv) {
    return request({
        url: `/member-skill/${dv}`,
        method: 'patch',
    })
}

// 上传个人头像
export function uploadFaceImg(data) {
    return request({
        url: `/upload/uploadFaceImg`,
        method: 'post',
        data: data
    })
}

// 新增作品案例
export function addCreation(data) {
    return request({
        url: `/member-creation`,
        method: 'post',
        data: data
    })
}

// 修改作品案例
export function updateCreation(data, dv) {
    return request({
        url: `/member-creation/${dv}`,
        method: 'put',
        data: data
    })
}

// 删除作品案例
export function deleteCreation(dv) {
    return request({
        url: `/member-creation/${dv}`,
        method: 'patch',
    })
}

// 分页查询简历附件
export function resumeFiles(path) {
    return request({
        url: `/resume-file/page/${path.number}/${path.limit}`,
        method: 'get',
    })
}

// 按用户id生成 获取简历地址
export function getUserResume(uid) {
    return request({
        url: `/resume-template/getUserTemplate/${uid}`,
        method: 'get',
    })
}

// 识别最后上传的简历附件
export function discernResume() {
    return request({
        url: `/resume-file/discernResume`,
        method: 'get',
    })
}

// 分页查询个人网页端banner配置
export function pagePCMember(type) {
    return request({
        url: `/open/banner/pagePCMember/${type}/${100}`,
        method: 'get',
    })
}
// 删除简历附件
export function deleteResumeFile(dv) {
    return request({
        url: `/resume-file/${dv}`,
        method: 'patch',
    })
}
// 列表查询简历模板
export function resumeTemplate() {
    return request({
        url: `/resume-template`,
        method: 'get',
    })
}
// 修改人才用户的模版ID
export function setResumeTemplate(templateId) {
    return request({
        url: `/member/setResumeTemplate/${templateId}`,
        method: 'put',
    })
}
// 修改人才用户的模版ID
export function updateCateIds(data, dv) {
    return request({
        url: `/member/updateCateIds/${dv}`,
        method: 'put',
        data: data
    })
}
// 消息列表
export function messagepage(data, path) {
    return request({
        url: `/message/page-mine/${path.number}/${path.limit}`,
        method: 'get',
        params: data
    })
}
// 城市数据
export function mapStaticJson() {
    return request({
        url: `/open/city/all`,
        method: 'get',
    })
}
export function companyTag() {
    return request({
        url: `/open/company-tag/pageByGroup/${1}/${50}`,
        method: 'get',
        params: {groupId: 1}
    })
}
// 上传意见反馈图片,批量上传
export function feedbackImages(data) {
    return request({
        url: `/upload/feedback/images`,
        method: 'post',
        data: data
    })
}
// 未读消息数
export function messageunread() {
    return request({
        url: `/message/unread-count`,
        method: 'get',
    })
}
// 全部消息已读
export function readAll() {
    return request({
        url: `/message/readAll`,
        method: 'put',
    })
}
