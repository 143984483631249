import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        info: '',
        area: '',
        showLogin: false,
        token: ''
    },
    mutations: {
        userInfo(state, info) {
            state.info = info
            localStorage.setItem('info', JSON.stringify(info));
        },
        getToken(state, token) {
            state.token = token
            localStorage.setItem('token', token);
        },
        area(state, area) {
            state.area = area
            localStorage.setItem('area', JSON.stringify(area));
        },
        showLogin(state, showLogin) {
            state.showLogin = showLogin
        }
    },
    getters: {
        userInfo(state) {
            return state.info || JSON.parse(localStorage.getItem('info')) || {}
        },
        token(state) {
            return state.token || localStorage.getItem('token') || ''
        },
        showLogin(state) {
            return state.showLogin
        },
        area(state) {
            if (localStorage.getItem('area') == "undefined" ) {
                localStorage.setItem('area', JSON.stringify({id: '', name: '全国'}));
            }
            return state.area || (localStorage.getItem('area') == undefined ? {id: '', name: '全国'} : JSON.parse(localStorage.getItem('area'))) || {id: 310100, name: '上海市'}
        }
    },
    actions: {},
    modules: {}
})
