import request from '@/utils/request'

// 列表查询平台标签
export function getTag(limit) {
    return request({
        url: `/open/company-tag/list/${limit}`,
        method: 'get',
    })
}

// 分页查询公司招聘职位
export function pageJobs(params, path) {
    return request({
        url: `/open/company-job/pageJobs/${path.number}/${path.limit}`,
        method: 'get',
        params: params
    })
}

// 查询公司招聘职位详情
export function JobsDetail(id) {
    return request({
        url: `/open/company-job/job/detail/${id}`,
        method: 'get'
    })
}

// 按 id 查询公司信息
export function findByAesId(id) {
    return request({
        url: `/open/company/findByAesId/${id}`,
        method: 'get'
    })
}

// 新增求职者投递简历
export function jobMember(data) {
    return request({
        url: `/company-job-member`,
        method: 'post',
        data: data
    })
}

// 职位分类静态数据资源
export function treeJobCategory() {
    return request({
        url: `/open/treeJobCategory/static`,
        method: 'get',
    })
}

// 分页查询求职者投递简历列表
export function pageMine(query, path) {
    return request({
        url: `/company-job-member/page-mine/${path.number}/${path.limit}`,
        method: 'get',
        params: query
    })
}

// 分页查询人员职位浏览记录
export function pageVO(path) {
    return request({
        url: `/job-browses/pageVO/${path.number}/${path.limit}`,
        method: 'get'
    })
}

// 收藏职位
export function memberCollection(data) {
    return request({
        url: `/member-collection`,
        method: 'post',
        data: data
    })
}

// 取消收藏
export function cancelByJid(jid) {
    return request({
        url: `/member-collection/cancelByJid/${jid}`,
        method: 'put',
    })
}

// 收藏列表
export function collectionList(query, path) {
    return request({
        url: `/member-collection/page/${path.number}/${path.limit}`,
        method: 'get',
        params: query
    })
}

// 取消面试
export function cancel(data, dv) {
    return request({
        url: `/company-job-member/cancel/${dv}`,
        method: 'put',
        data: data
    })
}

// 求职者面试结束登记
export function interviewConfirm(dv, data) {
    return request({
        url: `/company-job-member/interviewConfirm/${dv}`,
        method: 'put',
        data: data
    })
}

// 查询个人用户积分
export function integralInfo() {
    return request({
        url: `/integral/personal/info`,
        method: 'get',
    })
}

// 积分明细
export function integralMx(query, path) {
    return request({
        url: `/integral-mx/pageVO/${path.number}/${path.limit}`,
        method: 'get',
        params: query
    })
}

// 新增永华反馈意见
export function addFeedback(data) {
    return request({
        url: `/user-feedback`,
        method: 'post',
        data: data
    })
}

// 列表查询平台标签根据分组枚举
export function listByGroupEnum(limit) {
    return request({
        url: `/open/company-tag/listByGroupEnum/${limit}`,
        method: 'get',
    })
}

// 修改人才用户基础信息的求职意向Tag
export function updateJobTag(data, dv) {
    return request({
        url: `/member/updateJobTag/${dv}`,
        method: 'put',
        data: data
    })
}

// 按 id 查询人才基础用户
export function findById(id) {
    return request({
        url: `/member/findById/${id}`,
        method: 'get',
    })
}

// 获取个人简历信息
export function getCurrentResume() {
    return request({
        url: `/member/getCurrentResume`,
        method: 'get',
    })
}

// 新增简历附件
export function resumeFile(data) {
    return request({
        url: `/resume-file`,
        method: 'post',
        data: data
    })
}
// 识别简历
export function resumeParse(data) {
    return request({
        url: `/resume-file/resume/parse`,
        method: 'post',
        data: data
    })
}
// 识别简历
export function getUserTemplate(id) {
    return request({
        url: `/resume-template/getUserTemplate/${id}`,
        method: 'get'
    })
}
// 修改简历附件
export function updateresumeFile(data, dv) {
    return request({
        url: `/resume-file/${dv}`,
        method: 'put',
        data: data
    })
}
// 上传简历附件
export function uploadResumeFile(data) {
    return request({
        url: `/upload/uploadResumeFile`,
        method: 'post',
        data: data
    })
}

// 分页查询公司招聘职位(热招职位)
export function pageHotVO(query, path) {
    return request({
        url: `/open/company-job/pageHotVO/${path.number}/${path.limit}`,
        method: 'get',
        params: query
    })
}

// 分页查询(热招职位名)
export function getHotWordJobs(query, path) {
    return request({
        url: `/open/company-job/pageHotJob/${path.number}/${path.limit}`,
        method: 'get',
        params: query
    })
}

// 分页查询公司招聘职位(推荐职位)
export function pageSellVO(query, path) {
    return request({
        url: `/open/company-job/pageSellVO/${path.number}/${path.limit}`,
        method: 'get',
        params: query
    })
}

// 分页查询公司招聘职位(推荐职位)
export function companyPage(query, path) {
    return request({
        url: `/open/company/page/${path.number}/${path.limit}`,
        method: 'get',
        params: query
    })
}

// 分页查询公司招聘职位(推荐职位)
export function updateBlacklist(data, dv) {
    return request({
        url: `/member/updateBlacklist/${dv}`,
        method: 'put',
        data: data
    })
}
