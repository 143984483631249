<template>
  <div class="chooseAreaWrap">
    <slot></slot>
    <el-dialog
        title="切换城市"
        :visible.sync="dialogVisible"
        width="886px"
        :before-close="handleClose">
      <div class="chooseArea">
        <div class="retrieval search">
          <div class="items">
            <div class="title">搜索城市</div>
            <el-select
                @change="changeSelect3"
                placeholder="请搜索城市"
                remote
                v-model="selectValue"
                filterable
                :filter-method="filterMethod"
            >
              <el-option v-for="item in options" :key="item.id" :label="item.name" :value="item"></el-option>
            </el-select>
          </div>
          <div class="items sheng">
            <div class="title">省份</div>
            <el-select
                placeholder="请选择"
                v-model="selectValue4"
                @change="changeSelect2"
            >
              <el-option v-for="item in province" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
          <div class="items sheng">
            <div class="title">城市</div>
            <el-select
                @change="changeSelect"
                placeholder="请选择"
                v-model="selectValue3"
            >
              <el-option v-for="item in cityLists" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </div>
        </div>
        <div class="Popular">
          <div class="title">热门城市</div>
          <div class="PopularCity">
            <div class="its" v-for="(item, index) in PopularCity" @click="clickItem(item)" :key="index">
              {{ item.name }}
            </div>
          </div>
        </div>
        <div class="letter">
          <div class="title">按字母选择</div>
          <div class="city">
            <div
                class="city-item"
                @click="clickChat(item)"
                v-for="(item, index) in Object.keys(cities)"
                :key="index"
            >{{ item }}
            </div>
          </div>
        </div>

        <div class="scrollbar">
          <div class="itemss" v-for="(value, key) in cities" :key="key">
            <div class="wrap" :class="[active === key ? 'currentss' : '',current === key?'current': '']" :id="key"
                 @mouseover="handleOver(key)">
              <div class="name" :span="2">{{ key }}:</div>
              <div :span="22" class="city-name">
                <div
                    @click="clickItem(item)"
                    class="city-name-item"
                    v-for="(item, index) in value"
                    :key="index"
                >
                  <div>{{ item.name }}</div>
                </div>
              </div>
              <div class="gengduo" @click="launch(key)">
                <span>更多</span>
                <img src="https://static.dasdt.cn/images/gengduo.png">
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>

</template>

<script>
import {getAreaByCity, getAreasByCity, getCityBy, getMap, hotList, mapStaticJson} from "@/api/currency";
import AMapLoader from '@amap/amap-jsapi-loader'
import province from '@/assets/tree.json'
export default {
  props: {
    value: Boolean
  },
  watch: {
    value(newVal) {
      if (newVal) {
        this.dialogVisible = true
      }
    }
  },
  data() {
    return {
      selectValue4: '',
      selectValue3: '',
      province: province.data,
      visible: false, // 控制弹出层的显示
      selectValue: '',//下拉框的值 搜索下拉框
      options: [], //下拉框显示城市的数据
      cities: {}, //所有的城市数据
      dialogVisible: false,
      PopularCity: [],
      current: 'A',
      active: '',
      // cityList: []
    }
  },
  computed: {
    cityLists() {
      const arr = this.province.filter(item => {
        if (item.id === this.selectValue4) {
          return item.nodes
        }
      })
      return arr.length > 0 ? arr[0].nodes : []
    }
  },
  mounted() {
    this.getMap()
    this.hotList()
    // 获取下拉框的城市数据
    let values = Object.values(this.cities).flat(2)
    this.options = values
    this.mapStaticJson()
  },
  methods: {
    async mapStaticJson() {
      await mapStaticJson()
      // this.cityList = data[0].data
    },
    // 获取经纬度
    async getLocation() {
      try {
        const AMap = await AMapLoader.load({
          key: "fe972c494694388d383a477b23bc919d",
          version: "2.0",
          plugins: ["AMap.Geolocation"]
        });

        const geolocation = new AMap.Geolocation({
          enableHighAccuracy: true,
          timeout: 10000,
          buttonPosition: "RB"
        });

        geolocation.getCurrentPosition((status, result) => {
          if (status === "complete") {
            const obj = {
              lat: result.position.lat,
              lon: result.position.lng
            }
            this.getCityBy(obj)
          } else {
            console.error("获取经纬度失败");
          }
        });
      } catch (error) {
        console.error("加载高德地图 SDK 失败", error);
      }
    },
    // 根据经纬度获取城市
    async getCityBy(val) {
      const {data} = await getCityBy(val)
      await this.getAreaByCity(data[0])
    },
    //获取拼音城市数据
    async getMap() {
      const {data} = await getMap()
      this.cities = data[0].data[0]
    },
    async getAreaByCity(city) {
      const {data} = await getAreaByCity(city)
      this.$store.commit('area', data[0][0])

    },
    // 获取热门城市
    async hotList() {
      const {data} = await hotList()
      data.unshift({
        id: '',
        name: '全国'
      })
      this.PopularCity = data
    },
    launch(index) {
      if (index === this.active) {
        this.active = ''
        return
      }
      this.active = index
      // this.cities[index].isShow = !this.cities[index].isShow
    },
    handleOver(el) {
      this.current = el
    },
    handleClose() {
      this.$emit('input', false);
      this.dialogVisible = false
    },
    // 点击每个城市
    clickItem(item) {
      // 关闭弹出层
      this.$emit('changeCity', item.name)
      this.$store.commit('area', item)
      this.dialogVisible = false
      this.$emit('input', false);
    },
    // 点击字母区域
    clickChat(item) {
      let el = document.getElementById(item)
      if (el) el.scrollIntoView()
    },
    // 自定义搜索过滤
    async filterMethod(val) {
      const {data} = await getAreasByCity(val)
      this.options = data[0]
    },
    // 下拉框选择
    changeSelect(val) {
      const obj = this.cityLists.filter(item => {
        if (item.id === val) {
          return item
        }
      })
      this.$store.commit('area', obj[0])

      // this.$emit('changeCity', val.name)

      this.dialogVisible = false
      this.$emit('input', false);
    },
    changeSelect3(val) {
      this.$store.commit('area', val)
      this.dialogVisible = false
      this.selectValue = ''
      this.$emit('input', false);
    },
    changeSelect2() {
      this.selectValue3 = ''
    }
  }
}

</script>

<style lang='scss'>
.chooseAreaWrap {
  .result {
    cursor: pointer;
    padding: 9px 14px;
    border-radius: 5px;
    background: #F8F8F8;
    display: flex;
    align-items: center;
    gap: 4px;

    img {
      width: 14px;
      height: 14px;
    }

    span {
      color: #3A3A3A;
      font-size: 14px;
      font-weight: 400;
    }
  }
}


.rotate {
  transform: rotate(180deg);
}

svg {
  width: 1em;
  height: 1em;
  position: relative;
  top: 2px;
  margin-left: 4px;
  transition: all 0.25s linear;
}

.chooseArea {
  .letter {
    margin-top: 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .city {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &-item {
        color: #ADADAD;
        font-size: 14px;
        font-weight: 500;
        padding: 3px 6px;
        margin-right: 5px;
        cursor: pointer;
      }
    }

    .title {
      font-weight: 500;
      color: #1F1F1F;
      font-size: 16px;
    }
  }

  .Popular {
    margin-top: 24px;

    .PopularCity {
      margin-top: 12px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;

      .its {
        cursor: pointer;
        padding: 10px 18px;
        background: #F8F8F8;
        border-radius: 4px;
        color: #555555;
        font-size: 14px;
      }
    }

    .title {
      font-weight: 500;
      color: #1F1F1F;
      font-size: 16px;
    }
  }

  .retrieval {
    background: #F8F8F8;
    padding: 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 18px;
    .search{
      .el-select {
        width: 261px;
      }
    }
    .sheng{
      .el-select {
        width: 184px;
      }
    }

    .items {
      display: flex;
      align-items: center;
      gap: 10px;



      .el-input__inner {
        border-radius: 8px;

      }

      .title {
        color: #555555;
        font-size: 14px;
        font-weight: 400;
      }
    }

  }

  .scrollbar {
    margin-top: 16px;
    max-height: 300px;
    overflow-y: scroll;

    .itemss {
      .currentss {
        height: auto !important;
      }

      .current {
        border-radius: 8px;
        border-left: 4px solid #3A76FF !important;
        background: #F8F8F8 !important;
      }

      .wrap {
        border-left: 4px solid #fff;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        height: 46px;
        overflow: hidden;
        padding: 0 14px;

        .gengduo {
          margin-top: 14px;
          cursor: pointer;
          display: flex;
          gap: 4px;

          img {
            width: 8.92px;
            height: 5.38px;
            margin-top: 8px;
          }

          span {
            color: #ADADAD;
            font-size: 14px;
            font-weight: 400;
          }
        }

        .name {
          color: #999999;
          font-size: 22px;
          font-weight: 600;
          margin-right: 28px;
          line-height: 46px;
        }

        .city-name {
          width: 690px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;


          .city-name-item {
            margin-right: 28px;
            cursor: pointer;
            color: #333333;
            font-size: 14px;
            font-weight: 400;
            line-height: 46px;
          }
        }
      }
    }
  }

}


</style>
