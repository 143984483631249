import request from '@/utils/request'

// 获取课程信息
export function getCourseDetails(path) {
    return request({
        url: `/open/rt-course/detail/${path}`,
        method: 'get',
    })
}

// 获取视频文件
export function getPolyvToken(path) {
    return request({
        url: `/open/rt-course/getPolyvToken/${path}`,
        method: 'get',
    })
}

// 课程分类列表
export function classify() {
    return request({
        url: `/open/rt-course/course/classify`,
        method: 'get',
    })
}

// 选课列表
export function records(data, path) {
    return request({
        url: `/open/rt-course/records/${path.number}/${path.limit}`,
        method: 'get',
        params: data
    })
}

// 课程试听列表
export function videos(path) {
    return request({
        url: `/open/rt-course/videos/${path}`,
        method: 'get',
    })
}
// 开课计划
export function planCourseNew(data, path) {
    return request({
        url: `/open/rt-course/planCourseNew/${path.number}/${path.size}`,
        method: 'get',
        params: data
    })
}
